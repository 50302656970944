import './App.css';
import { useEffect, useState } from "react";

function Combination({ fee }) {
  const [testResult, setTestResult] = useState();
  const test = () => {
    setTestResult("pending")
    fetch(`https://mia.fee.xhain.io/${fee}/mainnet/`, {
      "headers": {
        "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      },
      "referrerPolicy": "strict-origin-when-cross-origin",
      "body": null,
      "method": "GET",
      "mode": "cors",
      "credentials": "omit"
    })
      .then(res => {
        res.json().then(result => {
          console.log(result)
          if (result?.speeds) {
            setTestResult(JSON.stringify(result.speeds[0],null,2))
          } else if(result?.fastest){
            setTestResult(JSON.stringify(result,null,2))
          } else {
            setTestResult(`no response`)
          }
        }).catch(e => {
          setTestResult("failed")
        })
      })
      .catch(e => {
        setTestResult("failed")
      })
  }
  return <tr>
    <td>
      {fee}
    </td>
    <td>
      {
        testResult
      }
    </td>
    <td>
      <button className="feeTestBtn" onClick={test}>
        test
      </button>
    </td>
  </tr>
}

function Fee() {
  const fee_symbols_map = ['btc', 'eth']
  const [fee_symbols, setFee_symbols] = useState(fee_symbols_map)
  const [fee_symbols_text, setFee_symbols_text] = useState(fee_symbols_map.join(','))

  useEffect(() => {
    if (localStorage.getItem("fee")) {
      setFee_symbols_text(localStorage.getItem("fee"))
    }
  }, [])

  return (
    <div className="Fee">
      <div style={{ display: "flex", gap: 50 }}>
        <label htmlFor="">fee</label>
        <textarea name="" id="" cols="30" rows="3" value={fee_symbols_text} onChange={e => {
          setFee_symbols_text(e.target.value)
          localStorage.setItem("fee", e.target.value)
          setFee_symbols(e.target.value.split(","))
        }}/>
      </div>

      <div style={{ display: "flex" }}>
        <button onClick={() => {
          for (let i of document.getElementsByClassName("feeTestBtn")) {
            i.click()
          }
        }}>test all
        </button>
      </div>
      <br/>
      <table border="1">
        <thead>
        <tr>
          <th>Combination</th>
          <th>test result</th>
          <th>action</th>
        </tr>
        </thead>
        <tbody>
        {
          fee_symbols.map(fee => {
            if (!fee) {
              return null;
            }
            return <Combination fee={fee} />
          })
        }
        </tbody>
      </table>
    </div>
  );
}

export default Fee;
