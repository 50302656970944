import './App.css';
import React, { useState } from "react";
import Rates from './rates';
import Fee from './fee'
import Tranfer from "./tranfer";

function App() {
  const [tab, setTab] = useState("rates");

  return (
    <div className="App" style={{ padding: "10%" }}>
      <div style={{ display: "flex", gap: 30, fontWeight: "normal", cursor: "pointer" }}>
        <h1 style={{ fontWeight: tab === "rates" ? "bolder" : "normal" }} onClick={() => {
          setTab("rates")
        }}>Rates</h1>
        <h1 style={{ fontWeight: tab === "calculation" ? "bolder" : "normal" }} onClick={() => {
          setTab("calculation")
        }}>Rate Calculation</h1>
        <h1 style={{ fontWeight: tab === "fee" ? "bolder" : "normal" }} onClick={() => {
          setTab("fee")
        }}>Fee</h1>
      </div>

      {
        tab === 'rates' && <Rates/>
      }
      {
        tab === 'fee' && <Fee/>
      }
      {
        tab === 'calculation' && <Tranfer/>
      }
    </div>
  );
}

export default App;
