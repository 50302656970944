import './App.css';
import { useEffect, useState } from "react";

function Combination({ from_symbol, to_symbol }) {
  const [testResult, setTestResult] = useState();
  const test = () => {
    console.log(to_symbol)
    setTestResult("pending")
    fetch(`https://mia.rate.xhain.io/to${to_symbol}?currency=${from_symbol}&value=500`, {
      "headers": {
        "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      },
      "referrerPolicy": "strict-origin-when-cross-origin",
      "body": null,
      "method": "GET",
      "mode": "cors",
      "credentials": "omit"
    })
      .then(res => {
        res.json().then(result => {
          if (result?.sources) {
            setTestResult(`sources: ${result.sources}`)
          } else {
            setTestResult(`sources: 0`)
          }
        }).catch(e => {
          setTestResult("failed")
        })
      })
      .catch(e => {
        setTestResult("failed")
      })
  }
  return <tr>
    <td>
      {from_symbol}-{to_symbol}
    </td>
    <td>
      {
        testResult
      }
    </td>
    <td>
      <button className="tranferTestBtn" onClick={test}>
        test
      </button>
    </td>
  </tr>
}

function Tranfer() {
  const tranfer_from_symbols_map = ["btc", "eth"]
  const tranfer_to_symbols_map = ["usd", "eur", "gbp"]
  const [tranfer_from_symbols, setTranfer_from_symbols] = useState(tranfer_from_symbols_map)
  const [tranfer_to_symbols, setTranfer_to_symbols] = useState(tranfer_to_symbols_map)

  const [tranfer_from_symbols_text, setTranfer_from_symbols_text] = useState(tranfer_from_symbols_map.join(','))
  const [tranfer_to_symbols_text, setTranfer_to_symbols_text] = useState(tranfer_to_symbols_map.join(','))

  const [tranfer_excludedCombinations, setTranfer_excludedCombinations] = useState("btcjpy,ltcjpy")


  useEffect(() => {
    if (localStorage.getItem("tranfer_from_symbols_text")) {
      setTranfer_from_symbols_text(localStorage.getItem("tranfer_from_symbols_text"))
      setTranfer_from_symbols(localStorage.getItem("tranfer_from_symbols_text").split(","))
    }
    if (localStorage.getItem("tranfer_to_symbols_text")) {
      setTranfer_to_symbols_text(localStorage.getItem("tranfer_to_symbols_text"))
      setTranfer_to_symbols(localStorage.getItem("tranfer_to_symbols_text").split(","))
    }
    if (localStorage.getItem("tranfer_excludedCombinations")) {
      setTranfer_excludedCombinations(localStorage.getItem("tranfer_excludedCombinations"))
    }
  }, [])

  return (
    <div className="Tranfer">
      <div style={{ display: "flex", gap: 50 }}>
        <label htmlFor="">from symbols</label>
        <textarea name="" id="" cols="30" rows="3" value={tranfer_from_symbols_text} onChange={e => {
          setTranfer_from_symbols_text(e.target.value)
          localStorage.setItem("tranfer_from_symbols_text", e.target.value)
          setTranfer_from_symbols(e.target.value.split(","))
        }}/>
        <label htmlFor="">to symbols</label>
        <textarea name="" id="" cols="30" rows="3" value={tranfer_to_symbols_text} onChange={e => {
          setTranfer_to_symbols_text(e.target.value)
          localStorage.setItem("tranfer_to_symbols_text", e.target.value)
          setTranfer_to_symbols(e.target.value.split(","))
        }}/>
        <label htmlFor="">excluded combinations</label>
        <textarea name="" id="" cols="30" rows="3" value={tranfer_excludedCombinations} onChange={e => {
          setTranfer_excludedCombinations(e.target.value)
          localStorage.setItem("tranfer_excludedCombinations", e.target.value)
        }}/>
      </div>

      <div style={{ display: "flex" }}>
        <button onClick={() => {
          for (let i of document.getElementsByClassName("tranferTestBtn")) {
            i.click()
          }
        }}>test all
        </button>
      </div>
      <br/>
      <table border="1">
        <thead>
        <tr>
          <th>Combination</th>
          <th>test result</th>
          <th>action</th>
        </tr>
        </thead>
        <tbody>
        {
          tranfer_from_symbols.map(from_symbol => {
            return tranfer_to_symbols.map(to_symbol => {
              if (tranfer_excludedCombinations.split(",").includes(`${from_symbol}${to_symbol}`)) {
                return null;
              }
              return <Combination from_symbol={from_symbol} to_symbol={to_symbol}/>
            })
          })
        }
        </tbody>

      </table>

    </div>
  );
}

export default Tranfer;
