import './App.css';
import { useEffect, useState } from "react";

function Combination({ from_symbol, to_symbol }) {
  const [testResult, setTestResult] = useState();
  const test = () => {
    setTestResult("pending")
    fetch(`https://mia.rate.xhain.io/${from_symbol}${to_symbol}`, {
      "headers": {
        "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
      },
      "referrerPolicy": "strict-origin-when-cross-origin",
      "body": null,
      "method": "GET",
      "mode": "cors",
      "credentials": "omit"
    })
      .then(res => {
        res.json().then(result => {
          if (result?.sources) {
            setTestResult(`sources: ${result.sources}`)
          } else {
            setTestResult(`sources: 0`)
          }
        }).catch(e => {
          setTestResult("failed")
        })
      })
      .catch(e => {
        setTestResult("failed")
      })
  }
  return <tr>
    <td>
      {from_symbol}-{to_symbol}
    </td>
    <td>
      {
        testResult
      }
    </td>
    <td>
      <button className="testBtn" onClick={test}>
        test
      </button>
    </td>
  </tr>
}

function Rates() {
  const from_symbols_map = ["btc", "eth"]
  const to_symbols_map = ["usd", "eur", "gbp"]
  const [from_symbols, setFrom_symbols] = useState(from_symbols_map)
  const [to_symbols, setTo_symbols] = useState(to_symbols_map)

  const [from_symbols_text, setFrom_symbols_text] = useState(from_symbols.join(','))
  const [to_symbols_text, setTo_symbols_text] = useState(to_symbols_map.join(','))

  const [excludedCombinations, setExcludedCombinations] = useState("btcjpy,ltcjpy")


  useEffect(() => {
    if (localStorage.getItem("from_symbols_text")) {
      setFrom_symbols_text(localStorage.getItem("from_symbols_text"))
      setFrom_symbols(localStorage.getItem("from_symbols_text").split(","))
    }
    if (localStorage.getItem("to_symbols_text")) {
      setTo_symbols_text(localStorage.getItem("to_symbols_text"))
      setTo_symbols(localStorage.getItem("to_symbols_text").split(","))
    }
    if (localStorage.getItem("excludedCombinations")) {
      setExcludedCombinations(localStorage.getItem("excludedCombinations"))
    }
  }, [])

  return (
    <div className="Rates">
      <div style={{ display: "flex", gap: 50 }}>
        <label htmlFor="">from symbols</label>
        <textarea name="" id="" cols="30" rows="3" value={from_symbols_text} onChange={e => {
          setFrom_symbols_text(e.target.value)
          localStorage.setItem("from_symbols_text", e.target.value)
          setFrom_symbols(e.target.value.split(","))
        }}/>
        <label htmlFor="">to symbols</label>
        <textarea name="" id="" cols="30" rows="3" value={to_symbols_text} onChange={e => {
          setTo_symbols_text(e.target.value)
          localStorage.setItem("to_symbols_text", e.target.value)
          setTo_symbols(e.target.value.split(","))
        }}/>
        <label htmlFor="">excluded combinations</label>
        <textarea name="" id="" cols="30" rows="3" value={excludedCombinations} onChange={e => {
          setExcludedCombinations(e.target.value)
          localStorage.setItem("excludedCombinations", e.target.value)
        }}/>
      </div>

      <div style={{ display: "flex" }}>
        <button onClick={() => {
          for (let i of document.getElementsByClassName("testBtn")) {
            i.click()
          }
        }}>test all
        </button>
      </div>
      <br/>
      <table border="1">
        <thead>
        <tr>
          <th>Combination</th>
          <th>test result</th>
          <th>action</th>
        </tr>
        </thead>
        <tbody>
        {
          from_symbols.map(from_symbol => {
            return to_symbols.map(to_symbol => {
              if (excludedCombinations.split(",").includes(`${from_symbol}${to_symbol}`)) {
                return null;
              }
              return <Combination from_symbol={from_symbol} to_symbol={to_symbol}/>
            })
          })
        }
        </tbody>
      </table>
    </div>
  );
}

export default Rates;
